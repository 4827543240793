<template>
  <div class="industries">
    <nav-industries></nav-industries>
  </div>
</template>

<script>

import NavIndustries from '@/components/NavIndustries.vue'

export default {
  name: 'industries',
  components: {
    NavIndustries
  }
}

</script>

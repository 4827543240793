<template>
  <nav
    class="navbar navbar-secondary navbar-industries"
    role="navigation"
    aria-label="secondary navigation"
  >
    <div class="container">
      <div class="navbar-menu is-active">
        <div class="navbar-end">
          <router-link
            class="navbar-item"
            :class="{'is-active': isActive(navItem.id)}"
            v-for="navItem in navItems"
            :key="navItem.id"
            :to="'/industries/' + navItem.id"
          >{{navItem.title}}</router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavIndustries',
  props: {
    active_id: String
  },
  data: function () {
    return {
      navItems: [
        { id: 'energy', title: 'Iron Ore' },
        { id: 'mining', title: 'Gold' },
        { id: 'rare-earths', title: 'Nickel' },
        { id: 'manufacturing', title: 'Base Metals' },
        { id: 'utilities', title: 'Rare Earths' }
      ]
    }
  },
  methods: {
    isActive: function (industryId) {
      return industryId === this.active_id
    }
  }
}
</script>
